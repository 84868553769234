import React from "react";
import moneySaveImg from "../Assets/moneysave.png";
import timeSaveImg from "../Assets/timesave.png";
import serverlessIcon from "../Assets/serverless.png";
import tokenIcon from "../Assets/consumption.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const stats = [
  {
    id: 1,
    title: " Up to 50%",
    description: "of costs saved by choosing the cheapest deployment options.",
    end: 50,
    unit: "%",
    image: moneySaveImg,
    textBefore: "Up to",
  },
  {
    id: 2,
    title: "Over 1000’s",
    description: "engineering hours saved.",
    end: 1000,
    unit: "",
    image: timeSaveImg,
    textBefore: "Over",
  },
];

const deploymentStats = [
  {
    id: 1,
    title: "Pay only for the tokens used with Serverless Service Endpoints",
    description: "",
    image: serverlessIcon,
  },
  {
    id: 2,
    title: "Pay based on compute consumption with Hosted Endpoints",
    description: "",
    image: tokenIcon,
  },
];

const HeroStatistics = () => {
  return (
    <div className="bg-white pb-20 ">
      <div className="text-center mb-12">
        <h2 className="text-2xl lg:text-4xl mb-20 font-semibold text-gray-900 mt-20">
          Save time and money with EfficientAI from Day 1
        </h2>
      </div>

      <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        {stats.map((stat, index) => (
          <StatCard key={stat.id} stat={stat} custom={index} />
        ))}
      </div>

      <div className="text-center mt-16 mb-8">
        <h2 className="text-2xl lg:text-4xl font-semibold text-gray-900">
          Choose the deployment strategy which works best for your AI needs
        </h2>
      </div>

      <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        {deploymentStats.map((stat, index) => (
          <DeploymentCard key={stat.id} stat={stat} custom={index} />
        ))}
      </div>
    </div>
  );
};

const StatCard = ({ stat, custom }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false, 
    rootMargin: "0px",
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center border border-gray-300"
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, delay: custom * 0.2 } },
      }}
      custom={custom}
    >
      <img src={stat.image} alt={stat.title} className="w-12 h-12 mb-2" />
      <div>
        {stat.textBefore && (
          <span className="text-3xl font-normal text-gray-900">
            {stat.textBefore}{" "}
          </span>
        )}
        <motion.span
          className="text-4xl font-semibold text-gray-900"
          initial={{ count: 0 }}
          animate={{ count: stat.end }}
          transition={{ duration: 1 }}
        >
          <Counter end={stat.end} unit={stat.unit} inView={inView} />
        </motion.span>
      </div>
      <p className="text-gray-700 mt-1 ">{stat.description}</p>
    </motion.div>
  );
};

const DeploymentCard = ({ stat, custom }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false, 
    rootMargin: "0px",
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center border border-gray-300"
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, delay: custom * 0.2 } },
      }}
      custom={custom}
    >
      <img src={stat.image} alt={stat.title} className="w-12 h-12 mb-4" />
      <span className="text-lg font-semibold mb-2 text-gray-900">
        {stat.title}
      </span>
      <p className="text-yellow-600">{stat.description}</p>
    </motion.div>
  );
};

const Counter = ({ end, unit, inView }) => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!inView) {
      setCount(0);
      return;
    }

    let startTime;
    const duration = Math.min(1000, (end / 50) * 800);

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const currentCount = Math.min(
        end,
        Math.floor((progress / duration) * end)
      );

      setCount(currentCount);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, [end, inView]);

  return (
    <span>
      {count}
      {unit}
    </span>
  );
};

export default HeroStatistics;
