import React, { useState } from "react";
import { motion } from "framer-motion";

// Arrow Icon Component
const ArrowIcon = ({ isOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? "rotate-180" : ""}`}
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
  </svg>
);

export default function SingleQuestion({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <motion.div
      className="border border-gray-300 rounded-lg bg-white shadow-sm overflow-hidden mx-auto w-full md:w-[90%] lg:w-[70%] mb-4 transition-all duration-300 ease-in-out"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <article className="flex items-center justify-between p-4 bg-transparent cursor-pointer">
        <h2
          className="text-lg text-gray-900 font-medium hover:text-yellow-600 transition-colors"
          onClick={() => setShowAnswer(!showAnswer)}
        >
          {question}
        </h2>
        <button
          onClick={() => setShowAnswer(!showAnswer)}
          className="text-yellow-600 hover:text-yellow-500 transition-colors"
        >
          <ArrowIcon isOpen={showAnswer} />
        </button>
      </article>

      <motion.article
        className={`p-4 bg-transparent border-t border-gray-300 transition-all duration-300 ${showAnswer ? "max-h-screen" : "max-h-0 overflow-hidden"}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: showAnswer ? 1 : 0 }}
        transition={{ duration: 0.4 }}
      >
        {showAnswer && <p className="text-gray-800">{answer}</p>}
      </motion.article>
    </motion.div>
  );
}
