import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const Scale = () => {
  return (
    <div className="px-4">
      <motion.div
        className="container mx-auto items-center text-center rounded-xl m-16 bg-white shadow-sm shadow-gray-400 p-10 border border-gray-200"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <motion.h2
          className="text-3xl lg:text-5xl font-bold text-gray-900"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          Start for free. Scale without limits
        </motion.h2>

        <div className="flex justify-center mt-9">
          <NavLink to="https://calendly.com/tejasnarayan">
            <button className="bg-white text-gray-900 border-2 px-4 py-4 transition-all duration-150 rounded-md font-bold hover:bg-gray-100 hover:scale-105 mt-2">
              Book a Demo
            </button>
          </NavLink>
        </div>
      </motion.div>
    </div>
  );
};

export default Scale;
