import { motion } from "framer-motion";
import EndpointsImage from "../Assets/AiEndpointsImage.png";

const AiEndpointsImage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="hidden sm:flex w-full h-screen bg-white items-center justify-center p-4 my-10"
    >
      <div className="w-full  max-w-5xl shadow-2xl  bg-yellow-600 rounded-2xl overflow-hidden border-4 border-yellow-600 relative ">
        <img
          src={EndpointsImage}
          alt="AI Endpoints"
          className="w-full h-full object-cover object-center rounded-xl"
        />
      </div>
    </motion.div>
  );
};

export default AiEndpointsImage;
