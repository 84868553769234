import React from "react";
import { motion } from "framer-motion";
import { FaAws, FaMicrosoft, FaLinkedin } from "react-icons/fa";

import FaFlipkart from "../Assets/flipkart.png";
import tejasPic from "../Assets/avatars/tejas_image.jpeg";
import citriixLogo from "../Assets/citrix-logo.png";
import srivatsPic from "../Assets/avatars/srivatsa_image.jpeg";

const companyLogos = [
  { id: 1, icon: <FaAws />, name: "AWS" },
  { id: 2, icon: <FaMicrosoft />, name: "Microsoft" },
  {
    id: 3,
    icon: <img src={citriixLogo} alt="Citrix" className="h-12" />,
    name: "Citrix",
  },
  {
    id: 4,
    icon: <img src={FaFlipkart} alt="Flipkart" className=" h-12" />,
    name: "Flipkart",
  },
];

const teamMembers = [
  {
    id: 1,
    image: tejasPic,
    title: "Founder",
    name: "Tejas Narayan",
    linkedin: "https://www.linkedin.com/in/tejasnarayans/",
  },
  {
    id: 2,
    image: srivatsPic,
    title: "Co-Founder",
    name: "Srivatsa Sinha",
    linkedin: "https://www.linkedin.com/in/srivatsa-sinha-631ba9b9/",
  },
];

const AboutAndTeam = () => {
  return (
    <div className="bg-white min-h-screen py-20 px-4 lg:px-10">
      {/* Who we are Section */}
      <motion.div
        className="mx-auto px-6 py-8 rounded-lg backdrop-filter backdrop-blur-sm bg-white border border-gray-300 shadow-lg w-full max-w-[1900px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex flex-col md:flex-row justify-between items-center gap-10">
          <div className="flex flex-col items-center md:items-start gap-10 text-center md:text-left">
            <motion.h2
              className="text-4xl font-bold text-gray-900"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Who <span className="text-yellow-600">we are...</span>
            </motion.h2>
            <motion.p
              className="text-lg lg:text-xl font-medium text-gray-700 px-3"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              We have built mission critical infrastructure and machine learning
              platforms at
            </motion.p>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-6 lg:gap-16 mt-10 space-y-4 items-center md:space-y-0">
          {companyLogos.map((logo) => (
            <motion.div
              key={logo.id}
              className="text-gray-900  h-[100px] text-6xl opacity-80 hover:opacity-100 transform hover:scale-110 transition ease-in-out duration-300 bg-gray-300 p-4 rounded-lg  object-contain"
              whileHover={{ scale: 1.1 }}
              height="100"
              width="100"
              title={logo.name}
            >
              {logo.icon}
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Meet the Team Section */}
      <div className="bg-white py-10 mt-10">
        <motion.div
          className="mx-auto px-6 py-8 rounded-lg backdrop-filter backdrop-blur-sm bg-white border border-gray-300 shadow-lg max-w-[1900px] w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl font-bold text-gray-900 text-center mb-10">
            Meet the Team
          </h2>
          <div className="flex flex-col md:flex-row items-center gap-16 md:gap-x-40 justify-center">
            {teamMembers.map((member) => (
              <motion.div
                key={member.id}
                className="flex flex-col text-center bg-white rounded-lg p-6 shadow-lg transition-transform transform hover:scale-105"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img
                  src={member.image}
                  alt={member.title}
                  className="w-32 h-32 rounded-full object-cover mx-auto mb-4 border-4 border-gray-300"
                />
                <h3 className="text-2xl font-semibold text-gray-900">
                  {member.title}
                </h3>
                <p className="text-xl font-medium text-gray-700 mb-4">
                  {member.name}
                </p>
                {member.linkedin && (
                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="text-4xl mx-auto text-gray-900 hover:text-blue-600 transition-colors" />
                  </a>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutAndTeam;
