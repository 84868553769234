import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../Assets/efficientailogo.png";
import linkdin from "../Assets/linkedin-logo.png";
import twitter from "../Assets/x-logo-twittter.jpg";
import gmail from "../Assets/gmail-new.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (

    <footer className="w-full bg-white text-gray-900 py-6 border-t-2 border-gray-500">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between mb-10 py-6">
        <div className="flex items-center mb-4 md:mb-0">
          <NavLink
            to="/"
            onClick={scrollToTop}
            className="flex items-center gap-2"
          >
            <img src={logo} alt="Efficient AI Logo" className="w-10 h-auto" />
            <p className="font-bold text-xl text-gray-900">
              Efficient<span className="text-yellow-600">AI</span>
            </p>
          </NavLink>
        </div>

        <div className="flex space-x-6">
          <NavLink
            to="/"
            onClick={scrollToTop}
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600"
                : "text-gray-900 hover:text-yellow-600"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            onClick={scrollToTop}
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600"
                : "text-gray-900 hover:text-yellow-600"
            }
          >
            About
          </NavLink>
          <NavLink
            to="/blogs"
            onClick={scrollToTop}
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600"
                : "text-gray-900 hover:text-yellow-600"
            }
          >
            Blog
          </NavLink>
          <NavLink
            to="/privacy-policy"
            onClick={scrollToTop}
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600"
                : "text-gray-900 hover:text-yellow-600"
            }
          >
            Privacy Policy
          </NavLink>
        </div>

        <div className="flex items-center mt-4 md:mt-0 space-x-4">
          <a
            href="https://www.linkedin.com/company/efficientaicloud"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkdin} alt="LinkedIn" className="w-6 h-6" />
          </a>
          <a
            href="https://x.com/AiEfficient"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="Twitter" className="w-6 h-6" />
          </a>
          <a href="mailto:tejas@efficientai.cloud">
            <img src={gmail} alt="Email" className="w-6 h-6" />
          </a>
        </div>
      </div>

      <div className="container mx-auto mt-4 border-t border-gray-200 pt-4">
        <p className="text-center text-sm">
          © {new Date().getFullYear()} EfficientAI.cloud. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
