import React from "react";
import { SiMicrosoftazure, SiGooglecloud } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { motion } from "framer-motion";
import FlowChartLogo from "../Assets/efficientailogo.png";
import awsBedrock from "../Assets/awsbedrock.png";
import awsSageMaker from "../Assets/sagemaker.jpg";
import azureFoundry from "../Assets/azureai.jpg";
import azureML from "../Assets/azureml.jpeg";
import azureOpenAI from "../Assets/azureopenai.jpeg";
import vertexAI from "../Assets/vertexai.jpg";

const AnimatedFlowChart = ({ width, heading }) => {
  const textHover = {
    color: "#fca311",
    scale: 1.02,
  };

  const platformAnimation = {
    initial: { opacity: 1 },
    animate: {
      opacity: [1, 1, 1],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="relative bg-gray-100 text-gray-900  border-2 border-gray-300 py-10 px-4 flex flex-col items-center space-y-8 md:space-y-16 ">
      <motion.div
        id="workloads"
        className="z-10 text-sm md:text-3xl font-bold border-2  border-gray-300 bg-white p-4 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <p>Your AI Workloads</p>
      </motion.div>

      {/*  EfficientAI + Features */}
      <motion.div
        id="api-layer"
        className="z-10 relative bg-white p-6 rounded-2xl  shadow-2xl w-full max-w-3xl flex flex-col items-center border-2 border-gray-300 mt-6"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={platformAnimation.animate}
        transition={{ duration: 0.5 }}
        style={{ boxShadow: "0 0 25px rgba(202, 120, 4, 0.8)" }}
      >
        {/* Logo & Company Name */}
        <div className="flex gap-2 items-center mb-6 justify-center">
          <img
            src={FlowChartLogo}
            alt="EfficientAI Logo"
            className="w-16 h-16"
          />
          <p className="text-xl font-bold">
            Efficient<span className="text-yellow-600">AI</span>
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-sm sm:text-base w-full">
          {[
            "One-click deployments",
            "Serverless & Hosted Endpoints",
            "Secure and Enterprise ready",
            "Tenant Isolation",
            "Centralized Monitoring & Observability",
            "Centralized Billing",
          ].map((feature, index) => (
            <motion.div
              key={index}
              whileHover={textHover}
              className="p-2 bg-gray-50/70 backdrop-blur-sm border border-gray-200 rounded-lg shadow-lg shadow-gray-200 text-center transition-all"
            >
              <p className="text-gray-900 text-sm">{feature}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      <div
        id="cloud"
        className="z-10 flex flex-col items-center text-center p-4 border-2 border-gray-400 rounded-lg gap-4 mt-10 max-w-3xl w-full pt-10"
      >
        <div className="flex justify-center gap-2 md:gap-16 lg:gap-20">
          {[
            {
              id: "aws",
              Icon: FaAws,
              services: [
                { name: "AWS Bedrock", img: awsBedrock },
                { name: "AWS SageMaker", img: awsSageMaker },
              ],
            },
            {
              id: "azure",
              Icon: SiMicrosoftazure,
              services: [
                { name: "Azure Foundry", img: azureFoundry },
                { name: "Azure ML", img: azureML },
                { name: "Azure OpenAI", img: azureOpenAI },
              ],
            },
            {
              id: "gcp",
              Icon: SiGooglecloud,
              services: [{ name: "Vertex AI", img: vertexAI }],
            },
          ].map(({ id, Icon, services }) => (
            <motion.div
              key={id}
              id={id}
              className="flex flex-col items-center bg-white  p-2 sm:p-4 rounded-lg border border-gray-700 xs:w-24 sm:w-32 md:w-48  min-w-1 shadow-lg"
            >
              <Icon className="text-2xl sm:text-4xl mb-2 text-gray-900" />
              {services.map((service, index) => (
                <div key={index} className="border-t border-gray-600 flex flex-col sm:flex-row items-center gap-1 my-1 py-1">
                  {service.img ? (
                    <img
                      src={service.img}
                      alt={service.name}
                      className="h-4 sm:h-6 rounded-full"
                    />
                  ) : (
                   ""
                  )}
                  <p className="text-[10px] md:text-sm  pt-1">
                    {service.name}
                  </p>
                </div>
              ))}
            </motion.div>
          ))}
        </div>

        <p className="text-xl md:text-2xl font-semibold mt-4">
          Multicloud AI Deployments
        </p>
      </div>

      <svg
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <defs>
          <filter id="pulseShadow" x="-20%" y="-20%" width="100%" height="100%">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="0.5"
              floodColor="white"
              floodOpacity="0.7"
            />
          </filter>
        </defs>

        <path
          id="pathTopToMiddle"
          d="M50,15 C50,28 50,32 50,40"
          stroke="#fca311"
          strokeWidth="0.2"
          fill="none"
        />

        <path
          id="pathMiddleAWS"
          d="M50,15 C45,20 40,50 25,75"
          stroke="#fca311"
          strokeWidth="0.2"
          fill="none"
        />

        <path
          id="pathMiddleAzure"
          d="M50,5 C50,60 50,60 50,75"
          stroke="#fca311"
          strokeWidth="0.2"
          fill="none"
        />

        <path
          id="pathMiddleGCP"
          d="M50,15 C55,20 60,50 75,75"
          stroke="#fca311"
          strokeWidth="0.2"
          fill="none"
        />

        <circle r="0.30" fill="#fff" filter="url(#pulseShadow)">
          <animateMotion dur="3.1s" repeatCount="indefinite" rotate="auto">
            <mpath xlinkHref="#pathTopToMiddle" />
          </animateMotion>
        </circle>
        <circle r="0.30" fill="#fff" filter="url(#pulseShadow)">
          <animateMotion dur="3.1s" repeatCount="indefinite" rotate="auto">
            <mpath xlinkHref="#pathMiddleAWS" />
          </animateMotion>
        </circle>
        <circle r="0.30" fill="#fff" filter="url(#pulseShadow)">
          <animateMotion dur="3.2s" repeatCount="indefinite" rotate="auto">
            <mpath xlinkHref="#pathMiddleAzure" />
          </animateMotion>
        </circle>
        <circle r="0.30" fill="#fff" filter="url(#pulseShadow)">
          <animateMotion dur="3s" repeatCount="indefinite" rotate="auto">
            <mpath xlinkHref="#pathMiddleGCP" />
          </animateMotion>
        </circle>
      </svg>
    </div>
  );
};

export default AnimatedFlowChart;
